(function() {
    'use strict';

    angular
        .module('schlingerApp')
        .controller('SlideImageDetailController', SlideImageDetailController);

    SlideImageDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'SlideImage', 'Slide'];

    function SlideImageDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, SlideImage, Slide) {
        var vm = this;

        vm.slideImage = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('schlingerApp:slideImageUpdate', function(event, result) {
            vm.slideImage = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
