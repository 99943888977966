(function() {
    'use strict';

    angular
        .module('schlingerApp')
        .controller('InformationDetailController', InformationDetailController);

    InformationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Information'];

    function InformationDetailController($scope, $rootScope, $stateParams, previousState, entity, Information) {
        var vm = this;

        vm.information = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('schlingerApp:informationUpdate', function(event, result) {
            vm.information = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
