(function() {
    'use strict';

    angular
        .module('schlingerApp')
        .controller('SlideDetailController', SlideDetailController);

    SlideDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Slide', 'SlideImage'];

    function SlideDetailController($scope, $rootScope, $stateParams, previousState, entity, Slide, SlideImage) {
        var vm = this;

        vm.slide = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('schlingerApp:slideUpdate', function(event, result) {
            vm.slide = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
