(function() {
  'use strict';

  angular
    .module('schlingerApp')
    .component('galleries', {
      templateUrl: 'app/components/galleries/galleries.html',
      controller: 'GalleriesController'
  });
})();
