(function() {
    'use strict';
    angular
        .module('schlingerApp')
        .factory('Email', Email);

    Email.$inject = ['$resource'];

    function Email ($resource) {
        var resourceUrl =  'api/mail';

        return $resource(resourceUrl, {}, {
            
        });
    }
})();
