(function () {
    'use strict';

    angular
        .module('schlingerApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', 'LoginService', '$state', '$window', '$uibModal', 'Information'];

    function HomeController($scope, Principal, LoginService, $state, $window, $uibModal, Information) {
        var vm = this;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;

        vm.openImpressum = openImpressum;
        vm.openDatenschutz = openDatenschutz;

        loadAll();

        $scope.$on('authenticationSuccess', function () {
            getAccount();
        });

        var rtime;
        var timeout = false;
        var delta = 200;
        angular.element($window).bind('resize', function () {
            rtime = new Date();
            if (timeout === false) {
                timeout = true;
                setTimeout(resizeend, delta);
            }
        });

        function resizeend() {
            if (new Date() - rtime < delta) {
                setTimeout(resizeend, delta);
            } else {
                timeout = false;
                $(".icon-box").removeClass('col-sm-4');
                setTimeout(function () {
                    $(".icon-box").addClass('col-sm-4');
                }, 0);
            }
        }

        getAccount();

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        function register() {
            $state.go('register');
        }

        $scope.mapClick = function () {
            $("#google-map").find('iframe').addClass('clicked');
        };

        $scope.mapLeave = function () {
            $("#google-map").find('iframe').removeClass('clicked');
        };

        function loadAll() {
            Information.query({}, onSuccess, onError);
            function onSuccess(data, headers) {
                for (var i = 0; i < data.length; i++) {
                    if (data[i].name === "impressum") {
                        vm.impressum = data[i];
                    }

                    if (data[i].name === "datenschutz") {
                        vm.datenschutz = data[i];
                    }
                }

                // vm.impressum = data.find(d => d.name === "impressum");
            }
            function onError(error) {

            }
        }

        function openImpressum() {
            $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'app/components/impressum/impressum.html',
                size: 'lg',
                controller: 'ImpressumController',
                controllerAs: 'vm',
                resolve: {
                    impressum: function () {
                        return vm.impressum;
                    }
                }
            });
        }

        function openDatenschutz() {
            $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'app/components/datenschutz/datenschutz.html',
                size: 'lg',
                controller: 'DatenschutzController',
                controllerAs: 'vm',
                resolve: {
                    datenschutz: function () {
                        return vm.datenschutz;
                    }
                }
            });
        }
    }
})();
