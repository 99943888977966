(function () {
  'use strict';

  angular
    .module('schlingerApp')
    .controller('EmailsController', EmailsController);

  EmailsController.$inject = ['$scope', 'Email', '$timeout', '$uibModal', 'Information'];

  function EmailsController($scope, Email, $timeout, $uibModal, Information) {

    var self = this;
    self.email = {
      subject: "",
      content: "",
      adress: "",
      tel: ""
    };
    self.sendEmail = function () {
      var mail = {
        subject: self.email.subject,
        content: self.email.subject + "<br />"
          + self.email.adress + "<br />"
          + self.email.tel + "<br />"
          + self.email.content,
        adress: self.email.adress,
        tel: self.email.tel
      };
      Email.save(mail);

      self.email = null;
      $scope.show = true;
      $timeout($scope.closeAlert, 2000);
    };

    $scope.closeAlert = function () {
      $scope.show = false;
    };

    self.openImpressum = openImpressum;
    self.openDatenschutz = openDatenschutz;

    loadAll();


    function loadAll() {
      Information.query({}, onSuccess, onError);
      function onSuccess(data, headers) {
        for (var i = 0; i < data.length; i++) {
          if (data[i].name === "impressum") {
            self.impressum = data[i];
            break;
          }

          if (data[i].name === "datenschutz") {
            self.datenschutz = data[i];
            break;
          }
        }

        // vm.impressum = data.find(d => d.name === "impressum");
      }
      function onError(error) {

      }
    }

    function openImpressum() {
      $uibModal.open({
        animation: true,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'app/components/impressum/impressum.html',
        size: 'lg',
        controller: 'ImpressumController',
        controllerAs: 'vm',
        resolve: {
          impressum: function () {
            return self.impressum;
          }
        }
      });
    }

    function openDatenschutz() {
      $uibModal.open({
        animation: true,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'app/components/datenschutz/datenschutz.html',
        size: 'lg',
        controller: 'DatenschutzController',
        controllerAs: 'vm',
        resolve: {
          datenschutz: function () {
            return self.datenschutz;
          }
        }
      });

    }

  }



})();
