(function () {
    'use strict';

    angular.module('schlingerApp')
        .controller('ImpressumController', ImpressumController);

    ImpressumController.$inject = ['$uibModalInstance', 'impressum'];

    function ImpressumController($uibModalInstance, impressum) {
        var vm = this;
        vm.clear = clear;
        vm.impressum = impressum;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
