(function() {
  'use strict';

  angular
    .module('schlingerApp')
    .component('slides', {
      templateUrl: 'app/components/slides/slides.html',
      controller: 'SlidesController'
  });
})();
