(function() {
    'use strict';

    angular
        .module('schlingerApp')
        .controller('GalleryItemDetailController', GalleryItemDetailController);

    GalleryItemDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'GalleryItem', 'Gallery'];

    function GalleryItemDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, GalleryItem, Gallery) {
        var vm = this;

        vm.galleryItem = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('schlingerApp:galleryItemUpdate', function(event, result) {
            vm.galleryItem = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
