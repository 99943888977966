(function () {
  'use strict';

  angular
    .module('schlingerApp')
    .factory('ImageConverter', ImageConverter);

  ImageConverter.$inject = ['$sce'];

  function ImageConverter ($sce) {

    return function(image, type) {
      var byteCharacters = atob(image);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var file = new Blob([byteArray], {type: type});
      var fileURL = URL.createObjectURL(file);
      return $sce.trustAsResourceUrl(fileURL);
    };

  }
})();
