(function() {
    'use strict';

    angular
        .module('schlingerApp')
        .controller('GalleryItemDialogController', GalleryItemDialogController);

    GalleryItemDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'GalleryItem', 'Gallery'];

    function GalleryItemDialogController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, GalleryItem, Gallery) {
    	var vm = this;

        vm.galleryItem = entity;
        vm.clear = clear;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.galleries = Gallery.query();
        vm.files;
		vm.galleryItems = [];

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.galleryItem.id !== null) {
            	GalleryItem.update(vm.galleryItem, onSaveSuccess, onSaveError);
            } else {
            	if(vm.galleryItems.length === 0){
            		GalleryItem.save(vm.galleryItem, onSaveSuccess, onSaveError);
            	}
            	else {
            		vm.sName = vm.galleryItem.name ? vm.galleryItem.name : vm.galleryItem.gallery.name;
                    vm.index = 0;
            		saveEach();
            	}
            }
        }

        function saveEach () {
        	var isLast = vm.galleryItems.length === 1;
			var str = "" + (++vm.index);
			var pad = "000";
			var ans = pad.substring(0, pad.length - str.length) + str;
			vm.galleryItem.name = vm.sName+"_"+ans;
			vm.galleryItem.image = vm.galleryItems[0].image;
			vm.galleryItem.imageContentType = vm.galleryItems[0].imageContentType;
        	vm.galleryItems.shift();
        	GalleryItem.save(vm.galleryItem, isLast ? onSaveSuccess : onSuccess, onSaveError);
        }
        
        function genEachImage (){
            vm.isSaving = true;
        	var file = vm.files[0];
            DataUtils.toBase64(file, function(base64Data) {
                $scope.$apply(function() {
                	var isLast = vm.files.length === 1;
                	var galleryItem = {};
                	galleryItem.image = base64Data;
                	galleryItem.imageContentType = file.type;
                	vm.files.shift();
                	vm.galleryItems.push(galleryItem);
                	if(!isLast){
                		genEachImage();
                	}
                	else {
                        vm.isSaving = false;
                	}
                });
            });
        }
        
        function onSaveSuccess (result) {
            $scope.$emit('schlingerApp:galleryItemUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }
        
        function onSuccess (result) {
            $scope.$emit('schlingerApp:galleryItemUpdate', result);
            saveEach();
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.setImage = function ($files, galleryItem) {
            vm.files = null;
            if ($files.length > 0) {
            	var isUpdate = vm.galleryItem.id !== null;
            	var isNewWithFile = vm.galleryItem.id === null && $files.length === 1;
            	if(isUpdate || isNewWithFile){
	                DataUtils.toBase64($files[0], function(base64Data) {
	                    $scope.$apply(function() {
	                        galleryItem.image = base64Data;
	                        galleryItem.imageContentType = $files[0].type;
	                    });
	                });
            	}
            	else {//this is new gallery item with multiple files
            		vm.files = $files;
            		vm.galleryItems = [];
            		genEachImage();
            	}
            }
        };


    }
})();
