(function () {
    'use strict';

    angular
        .module('schlingerApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$state', 'Auth', 'Principal', 'ProfileService', 'LoginService', '$uibModal', 'Information'];

    function NavbarController($state, Auth, Principal, ProfileService, LoginService, $uibModal, Information) {
        var vm = this;

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        ProfileService.getProfileInfo().then(function (response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;
        vm.gotoAnchor = gotoAnchor;
        vm.openImpressum = openImpressum;
        vm.openDatenschutz = openDatenschutz;

        loadAll();

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('home');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function gotoAnchor(id) {

            $(document).off("scroll");

            var width = $(window).width();
            var navbarH = 95;

            if (width <= 425)
                navbarH = 55;
            else if (width <= 425)
                navbarH = 80;
            else
                navbarH = 95;

            var scrollTop = id ? $('#' + id).offset().top - navbarH : 0;
            $('html, body').stop().animate({
                'scrollTop': scrollTop
            }, 500, 'swing', function () {
                $(document).on("scroll", onScroll);
            });

            collapseNavbar();

            $('.nav-text').removeClass("active");
            $('.' + id).addClass("active");

        }

        function loadAll() {
            Information.query({}, onSuccess, onError);
            function onSuccess(data, headers) {
                for (var i = 0; i < data.length; i++) {
                    if (data[i].name === "impressum") {
                        vm.impressum = data[i];
                    }

                    if (data[i].name === "datenschutz") {
                        vm.datenschutz = data[i];
                    }
                }

                // vm.impressum = data.find(d => d.name === "impressum");
            }
            function onError(error) {

            }
        }

        function openImpressum() {
            $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'app/components/impressum/impressum.html',
                size: 'lg',
                controller: 'ImpressumController',
                controllerAs: 'vm',
                resolve: {
                    impressum: function () {
                        return vm.impressum;
                    }
                }
            });
        }

        function openDatenschutz() {
            $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'app/components/datenschutz/datenschutz.html',
                size: 'lg',
                controller: 'DatenschutzController',
                controllerAs: 'vm',
                resolve: {
                    datenschutz: function () {
                        return vm.datenschutz;
                    }
                }
            });
        }

    }
})();
