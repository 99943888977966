(function() {
    'use strict';

    angular
        .module('schlingerApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('gallery-header', {
            parent: 'entity',
            url: '/gallery-header?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'GalleryHeaders'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/gallery-header/gallery-headers.html',
                    controller: 'GalleryHeaderController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }]
            }
        })
        .state('gallery-header-detail', {
            parent: 'entity',
            url: '/gallery-header/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'GalleryHeader'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/gallery-header/gallery-header-detail.html',
                    controller: 'GalleryHeaderDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'GalleryHeader', function($stateParams, GalleryHeader) {
                    return GalleryHeader.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'gallery-header',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('gallery-header-detail.edit', {
            parent: 'gallery-header-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/gallery-header/gallery-header-dialog.html',
                    controller: 'GalleryHeaderDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['GalleryHeader', function(GalleryHeader) {
                            return GalleryHeader.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('gallery-header.new', {
            parent: 'gallery-header',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/gallery-header/gallery-header-dialog.html',
                    controller: 'GalleryHeaderDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                image: null,
                                imageContentType: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('gallery-header', null, { reload: 'gallery-header' });
                }, function() {
                    $state.go('gallery-header');
                });
            }]
        })
        .state('gallery-header.edit', {
            parent: 'gallery-header',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/gallery-header/gallery-header-dialog.html',
                    controller: 'GalleryHeaderDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['GalleryHeader', function(GalleryHeader) {
                            return GalleryHeader.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('gallery-header', null, { reload: 'gallery-header' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('gallery-header.delete', {
            parent: 'gallery-header',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/gallery-header/gallery-header-delete-dialog.html',
                    controller: 'GalleryHeaderDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['GalleryHeader', function(GalleryHeader) {
                            return GalleryHeader.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('gallery-header', null, { reload: 'gallery-header' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
