(function () {
    'use strict';

    angular.module('schlingerApp')
        .controller('DatenschutzController', DatenschutzController);

    DatenschutzController.$inject = ['$uibModalInstance', 'datenschutz'];

    function DatenschutzController($uibModalInstance, datenschutz) {
        var vm = this;
        vm.clear = clear;
        vm.datenschutz = datenschutz;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
