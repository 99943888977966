(function() {
  'use strict';
 
  angular
    .module('schlingerApp')
    .controller('SlidesController', SlidesController);

  SlidesController.$inject = ['$scope', 'Slides' , 'ImageConverter'];

  function SlidesController ($scope, Slides, ImageConverter) {
    
    $scope.myInterval = 6000;
    $scope.noPause = true;
    $scope.active = 0;
    var currIndex = 0;

    loadAll();
    
    function loadAll() {
      Slides.query(function(result) {        
    	$scope.slides = result[0].images;        
        $scope.slides.map(function(item) {
          item.img = ImageConverter(item.image, item.imageContentType); //Image Converter
          item.id = currIndex++;
          return item;
        });
      });
    }
    setTimeout(function(){ $(".carousel-inner").addClass("slowSlide"); }, $scope.myInterval);
  }
  
})();