(function() {
    'use strict';

    angular
        .module('schlingerApp')
        .controller('GalleryItemDeleteController',GalleryItemDeleteController);

    GalleryItemDeleteController.$inject = ['$uibModalInstance', 'entity', 'GalleryItem'];

    function GalleryItemDeleteController($uibModalInstance, entity, GalleryItem) {
        var vm = this;

        vm.galleryItem = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            GalleryItem.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
