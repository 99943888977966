(function() {
    'use strict';

    angular
        .module('schlingerApp')
        .controller('GalleryHeaderDialogController', GalleryHeaderDialogController);

    GalleryHeaderDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'GalleryHeader', 'Gallery'];

    function GalleryHeaderDialogController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, GalleryHeader, Gallery) {
	  var vm = this;

      vm.galleryHeader = entity;
      vm.clear = clear;
      vm.byteSize = DataUtils.byteSize;
      vm.openFile = DataUtils.openFile;
      vm.save = save;
      vm.galleries = Gallery.query();
      vm.files;
      vm.galleryHeaders = [];

      $timeout(function (){
          angular.element('.form-group:eq(1)>input').focus();
      });

      function clear () {
          $uibModalInstance.dismiss('cancel');
      }

      function save () {
          vm.isSaving = true;
          if (vm.galleryHeader.id !== null) {
              GalleryHeader.update(vm.galleryHeader, onSaveSuccess, onSaveError);
          } else {
          	if(vm.galleryHeaders.length === 0){
          		GalleryHeader.save(vm.galleryHeader, onSaveSuccess, onSaveError);
          	}
          	else {
          		vm.sName = vm.galleryHeader.name;
                  vm.index = 0;
          		saveEach();
          	}
          }
      }

      function saveEach () {
      	var isLast = vm.galleryHeaders.length === 1;
		var str = "" + (++vm.index);
		var pad = "000";
		var ans = pad.substring(0, pad.length - str.length) + str;
		vm.galleryHeader.name = vm.sName+"_"+ans;
		vm.galleryHeader.image = vm.galleryHeaders[0].image;
		vm.galleryHeader.imageContentType = vm.galleryHeaders[0].imageContentType;
      	vm.galleryHeaders.shift();
  		GalleryHeader.save(vm.galleryHeader, isLast ? onSaveSuccess : onSuccess, onSaveError);
      }
      
      function genEachImage (){
          vm.isSaving = true;
      	var file = vm.files[0];
          DataUtils.toBase64(file, function(base64Data) {
              $scope.$apply(function() {
              	var isLast = vm.files.length === 1;
              	var galleryHeader = {};
              	galleryHeader.image = base64Data;
              	galleryHeader.imageContentType = file.type;
              	vm.files.shift();
              	vm.galleryHeaders.push(galleryHeader);
              	if(!isLast){
              		genEachImage();
              	}
              	else {
                      vm.isSaving = false;
              	}
              });
          });
      }
      
      function onSaveSuccess (result) {
          $scope.$emit('schlingerApp:galleryHeaderUpdate', result);
          $uibModalInstance.close(result);
          vm.isSaving = false;
      }
      
      function onSuccess (result) {
          $scope.$emit('schlingerApp:galleryHeaderUpdate', result);
          saveEach();
      }

      function onSaveError () {
          vm.isSaving = false;
      }

      vm.setImage = function ($files, galleryHeader) {
          vm.files = null;
          if ($files.length > 0) {
          	var isUpdate = vm.galleryHeader.id !== null;
          	var isNewWithFile = vm.galleryHeader.id === null && $files.length === 1;
          	if(isUpdate || isNewWithFile){
                DataUtils.toBase64($files[0], function(base64Data) {
                    $scope.$apply(function() {
                        galleryHeader.image = base64Data;
                        galleryHeader.imageContentType = $files[0].type;
                    });
                });
          	}
          	else {//this is new slide image with multiple files
          		vm.files = $files;
          		vm.galleryHeaders = [];
          		genEachImage();
          	}
          }
      };

    }
})();
