(function() {
    'use strict';

    angular
        .module('schlingerApp')
        .controller('SlideImageDialogController', SlideImageDialogController);

    SlideImageDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'SlideImage', 'Slide'];

    function SlideImageDialogController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, SlideImage, Slide) {
    	var vm = this;

        vm.slideImage = entity;
        vm.clear = clear;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.slides = Slide.query();
        vm.files;
        vm.slideImages = [];
        
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.slideImage.id !== null) {
                SlideImage.update(vm.slideImage, onSaveSuccess, onSaveError);
            } else {
            	if(vm.slideImages.length === 0){
            		SlideImage.save(vm.slideImage, onSaveSuccess, onSaveError);
            	}
            	else {
            		vm.sName = vm.slideImage.name;
                    vm.index = 0;
            		saveEach();
            	}
            }
        }

        function saveEach () {
        	var isLast = vm.slideImages.length === 1;
			var str = "" + (++vm.index);
			var pad = "000";
			var ans = pad.substring(0, pad.length - str.length) + str;
			vm.slideImage.name = vm.sName+"_"+ans;
			vm.slideImage.image = vm.slideImages[0].image;
			vm.slideImage.imageContentType = vm.slideImages[0].imageContentType;
        	vm.slideImages.shift();
    		SlideImage.save(vm.slideImage, isLast ? onSaveSuccess : onSuccess, onSaveError);
        }
        
        function genEachImage (){
            vm.isSaving = true;
        	var file = vm.files[0];
            DataUtils.toBase64(file, function(base64Data) {
                $scope.$apply(function() {
                	var isLast = vm.files.length === 1;
                	var slideImage = {};
                	slideImage.image = base64Data;
                	slideImage.imageContentType = file.type;
                	vm.files.shift();
                	vm.slideImages.push(slideImage);
                	if(!isLast){
                		genEachImage();
                	}
                	else {
                        vm.isSaving = false;
                	}
                });
            });
        }
        
        function onSaveSuccess (result) {
            $scope.$emit('schlingerApp:slideImageUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }
        
        function onSuccess (result) {
            $scope.$emit('schlingerApp:slideImageUpdate', result);
            saveEach();
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.setImage = function ($files, slideImage) {
            vm.files = null;
            if ($files.length > 0) {
            	var isUpdate = vm.slideImage.id !== null;
            	var isNewWithFile = vm.slideImage.id === null && $files.length === 1;
            	if(isUpdate || isNewWithFile){
	                DataUtils.toBase64($files[0], function(base64Data) {
	                    $scope.$apply(function() {
	                        slideImage.image = base64Data;
	                        slideImage.imageContentType = $files[0].type;
	                    });
	                });
            	}
            	else {//this is new slide image with multiple files
            		vm.files = $files;
            		vm.slideImages = [];
            		genEachImage();
            	}
            }
        };

    }
})();
