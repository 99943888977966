(function() {
  'use strict';

  angular
    .module('schlingerApp')
    .component('emails', {
      templateUrl: 'app/components/emails/emails.html',
      controller: 'EmailsController',
      controllerAs: 'vm'
  });
})();
