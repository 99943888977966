(function() {
  'use strict';
 
  angular
    .module('schlingerApp')
    .controller('GalleriesController', GalleriesController);

  GalleriesController.$inject = ['$scope', 'GalleryNoImages', 'GalleryHeader', 'GalleryItem', 'Galleries', 'Lightbox' ,'ImageConverter'];

  function GalleriesController ($scope, GalleryNoImages, GalleryHeader, GalleryItem, Galleries, Lightbox, ImageConverter) {

//    $scope.myInterval = 3000;
    $scope.myInterval = 6000;
    $scope.noPause = false;
    $scope.active = 0;
    $scope.index = 0;
    var currIndex = 0;

    loadAll();

    function loadAll() {
      GalleryNoImages.query(function(result) {
        $scope.galleries = result;
        for(var gallery in $scope.galleries){
          for(var header in $scope.galleries[gallery].headers){
            GalleryHeader.get({id:$scope.galleries[gallery].headers[header].id},function(res){
              var iGallery;
              var galleryHeader = res;
              for(var gallery in $scope.galleries){
                if($scope.galleries[gallery].id === res.gallery.id){  
                  iGallery = gallery;
                  break;
                }
              }
              var iHeader;
              for(var header in $scope.galleries[iGallery].headers){
                if($scope.galleries[iGallery].headers[header].id === res.id){  
                  iHeader = header;
                  break;
                }
              }
              $scope.galleries[iGallery].headers[iHeader].img = ImageConverter(res.image, res.imageContentType);
            });
          }
          for(var item in $scope.galleries[gallery].items){
            GalleryItem.get({id:$scope.galleries[gallery].items[item].id},function(res){
              var iGallery;
              var galleryItem = res;
              for(var gallery in $scope.galleries){
                if($scope.galleries[gallery].id === res.gallery.id){  
                  iGallery = gallery;
                  break;
                }
              }
              var iItem;
              for(var item in $scope.galleries[iGallery].items){
                if($scope.galleries[iGallery].items[item].id === res.id){  
                  iItem = item;
                  break;
                }
              }
              $scope.galleries[iGallery].items[iItem].url = $scope.galleries[iGallery].items[iItem].img = ImageConverter(res.image, res.imageContentType);
            });
          }
        }
        
//        Galleries.query(function(result) {     
//          $scope.galleries = result.map(function (gallery){          
//            gallery.headers = gallery.headers.map(function(header) {
//              header.img = ImageConverter(header.image, header.imageContentType); //Image Converter
//              return header;
//            });
//
//            gallery.items = gallery.items.map(function(item) {
//              item.img = ImageConverter(item.image, item.imageContentType); //Image Converter
//              item.id = currIndex++;
//              item.url = item.img;
//              return item;        
//            });
//
//            return gallery;
//          });
//
//          $scope.gallery = result[0];
//          $scope.gallery.class = "active";
//        });
          $scope.gallery = result[0];
          $scope.gallery.class = "active";
      });
    }
    
    setTimeout(function(){ $(".album-image").addClass("slowSlide"); }, $scope.myInterval);
    
    $scope.albumSelected = function( gallery , index) {
      $scope.gallery.class = "";
      gallery.class = "active";
      $scope.gallery = gallery;
      $scope.active = index;
      $("#image-items").fadeIn();
    };
    
    $scope.headerSelected = function() {
      $("#image-items").fadeIn();
    };
    
    $scope.closeAlbum = function() {
      $("#image-items").fadeOut();
    };
    
//  DISPLAY FULL SCREEN IMAGE WHEN IMAGE WAS SELECTED
    $scope.openLightboxModal = function (index) {
      Lightbox.openModal($scope.gallery.items, index);
    };
    
  }
  
})();
