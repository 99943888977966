(function() {
    'use strict';

    angular
        .module('schlingerApp')
        .controller('GalleryHeaderDetailController', GalleryHeaderDetailController);

    GalleryHeaderDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'GalleryHeader', 'Gallery'];

    function GalleryHeaderDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, GalleryHeader, Gallery) {
        var vm = this;

        vm.galleryHeader = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('schlingerApp:galleryHeaderUpdate', function(event, result) {
            vm.galleryHeader = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
